export default theme => ({ // eslint-disable-line
  pageListing: {
    marginTop: 30,
    marginBottom: 30,
    '& .wrapper': {
        '& .background-wrap': {
            padding: 12,
            width: 'calc(100% - 24px)',
            margin: '0 auto',
            borderRadius: 4,
            boxShadow: "0 8px 16px rgba(0,49,60,0.16)",
            backgroundColor: "#00313C",
            [theme.breakpoints.up('sm')]: {
                padding: '50px 8.333%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            '& .content': {
              [theme.breakpoints.up('sm')]: {
                    flex: '0 0 40%',
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 36%',
                },
                '& .button': {
                    marginLeft: 12,
                },
                '& .MuiTypography-root, & p': {
                    color: '#F7ECe0',
                }
            },
            '& .listing': {
                display: 'flex',
                flex: '0 0 100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                [theme.breakpoints.up('sm')]: {
                    flex: '0 0 60%',
                },
                '& > .side-card': {
                    flex: '0 0 calc(100% - 24px)',
                    margin: '0 12px 24px',
                    borderRadius: 4,
                    overflow: 'hidden',
                    backgroundColor: '#F7ECe0',
                    boxShadow: "0 8px 16px rgba(0,49,60,0.16)",
                    display: 'flex',
                    alignItems: 'center',
                    '& img': {
                        flex: '0 0 72px',
                        maxWidth: 72,
                        minHeight: 110,
                        objectFit: 'cover',
                    },
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 calc(50% - 24px)',
                    },
                },
                '& svg': {
                    width: 36,
                    height: 36,
                    flex: '0 0 36px',
                    position: 'relative',
                    zIndex: 2,
                    margin: '0 -18px',
                    '& .circ': { fill: '#B3D57C' },
                    '& .chev': { fill: '#00313C' },
                },
                '& .MuiTypography-root': {
                    padding: '12px 36px',
                    margin: 0,
                }
            }
        }
    }
  },
})
