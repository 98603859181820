export default theme => ({ // eslint-disable-line
  resultItem: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      padding: '32px 24px',
      [theme.breakpoints.up('md')]: {
          alignItems: 'center',
      },
      '&:nth-of-type(odd)': {
          backgroundColor: '#F3EEEC',
      },
      '& .number': {
        width: 32,
        height: 32,
        lineHeight: '32px',
        textAlign: 'center',
        color: theme.palette.secondary.cream,
        backgroundColor: theme.palette.primary.purple,
        flex: '0 0 32px',
        marginRight: 16,
        borderRadius: '50%',
        fontWeight: 'bold',
      },
      '& img': {
          width: 62,
          height: 62,
          marginRight: 36,
          objectFit: 'cover',
          borderRadius: theme.border.radius,
      },
      '& .stockist-info': {
          flex: '0 0 calc(100% - 98px)',
          [theme.breakpoints.up('md')]: {
            flex: '0 0 calc(100% - 680px)',
            paddingRight: 24,
          },
          '& h3': {
            fontSize: 24,
            letterSpacing: '0.008em',
            fontWeight: 'bold',
            marginTop: 0,
            fontFamily: theme.bodyFont,
            marginBottom: 4,
            color: theme.palette.primary.purple,
          },
          '& p': {
              fontFamily: theme.bodyFont,
              fontSize: 16,
              letterSpacing: '0.016em',
              marginTop: 0,
              marginBottom: 16,
              lineHeight: 1.5,
              color: theme.palette.primary.purple,
              [theme.breakpoints.up('md')]: {
                  marginBottom: 0,
              },
              '& a': {
                  fontWeight: 'bold',
                  transition: 'opacity .3s ease-in-out',
                  color: '#7CCC6C',
                  textDecoration: 'underline !important',
                  '&:hover, &:focus': {
                      color: '#009F4D'
                  }
              }
          }
      },
      '& .stockist-disatance, & .stockist-type': {
          flex: '0 0 calc(100% - 98px)',
          marginLeft: 98,
          fontSize: 16,
          fontWeight: 'bold',
          color: theme.palette.primary.purple,
          letterSpacing: '0.016em',
          padding: '12px 0',
          [theme.breakpoints.up('md')]: {
              padding: 0,
              flex: '1',
              marginLeft: 0,
          },
          '& svg': {
              marginRight: 10,
          },
          '& svg, & span': {
            display: 'inline-block',
            verticalAlign: 'middle',
          }
      },
      '& .stockist-link': {
          textAlign: 'center',
          flex: '0 0 100%',
          [theme.breakpoints.up('md')]: {
              padding: 0,
              flex: '1',
          },
          '& a': {
              fontSize: 18,
              fontWeight: 'bold',
              color: theme.palette.primary.navy,
              padding: '16px 24px',
              display: 'table',
              margin: '12px auto 0',
              backgroundColor: theme.palette.primary.green,
              boxShadow: theme.shadow,
              lineHeight: 1,
              borderRadius: theme.border.radius,
              [theme.breakpoints.up('md')]: {
                  marginTop: 0,
              },
              '& svg': {
                  marginLeft: 8,
              }
          }
      }
  },
})
