import React, { Component } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import GoogleMapReact from "google-map-react"
import { withStyles } from "@material-ui/styles"

import Intro from "../components/Stockist/Intro"
import Marker from "../components/Stockist/Marker"
import ResultItem from "../components/Stockist/ResultItem"
import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import Form from "../components/Stockist/Search/Form"

import arrow from "../images/select-arrow.webp"
import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock"
import { encodeHTML, isBrowser } from "../helpers"
import CookiesConsent from "../components/CookiesConsent"

const styles = {
  stockistBlock: {
    flex: "0 0 calc(100% - 24px)",
    margin: "0 12px",
    padding: "32px 0",
    borderRadius: 4,

    "& .MuiTypography-h2": {
      backgroundColor: "#E4DFD9",
      paddingLeft: 32,
      paddingBottom: 0,
      paddingTop: 48,
      marginBottom: 0,
    },
    "&.displaying-results": {
      "& .MuiTypography-h2": {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
  },
  subCatNav: {
    position: "relative",
    marginBottom: 30,
    marginTop: 30,
    "@media(min-width: 960px)": {
      borderBottom: "none",
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .count": {
      fontSize: 16,
      letterSpacing: "0.016em",
      lineHeight: 2,
      fontWeight: "normal",
      color: "#00313C",
      fontFamily: "Oxygen",
    },
    "& .sort-wrap": {
      marginTop: 12,
      overflow: "hidden",
      transition: "opacity .3s ease-in-out",
      "@media(min-width: 960px)": {
        height: "auto !important",
        opacity: "1 !important",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "row",
        marginTop: 0,
      },
      "& .select-row": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "12px 12px 0",
        alignItems: "center",
        "@media(min-width: 960px)": {
          padding: 12,
        },
        "& span": {
          fontSize: 12,
          fontFamily: "Oxygen",
          color: "#00313C",
          letterSpacing: "0.152em",
          lineHeight: "32px",
          textTransform: "uppercase",
          fontWeight: "bold",
          marginRight: 12,
        },
        "& select": {
          borderRadius: 4,
          cursor: "pointer",
          color: "#454545",
          border: "1px solid #ACA39A",
          fontSize: 16,
          letterSpacing: "0.016em",
          padding: "17px 45px 17px 20px",
          lineHeight: 1.1,
          fontFamily: "Oxygen",
          backgroundImage: "url(" + arrow + ")",
          backgroundPosition: "center right 15px",
          backgroundSize: "10px 6px",
          backgroundRepeat: "no-repeat",
          appearance: "none",
          outline: "none",
          "-webkit-appearance": "none",
          "-moz-appearance": "none",
          "-ms-appearance": "none",
          "@media(min-width: 960px)": {
            minWidth: 0,
          },
        },
        "&.show-count": {
          paddingBottom: 12,
        },
      },
    },
  },
  onlineRetailers: {
    "& .MuiTypography-h2": {
      paddingBottom: 32,
    },
    "& .actions": {
      padding: "0 12px",
      "& button": {
        fontFamily: "Oxygen",
        fontWeight: "bold",
        fontSize: 16,
        letterSpacing: "0.016em",
        lineHeight: 1,
        color: "#00313C",
        backgroundColor: "transparent",
        padding: "16px 32px",

        border: "none",
        "&.active": {
          backgroundColor: "#ACA39A",
        },
      },
    },
    "& .retailer-listing": {
      backgroundColor: "#E4DFD9",
      padding: "32px 0",
      "@media(min-width: 600px)": {
        padding: "72px 0",
      },
      "@media(min-width: 960px)": {
        padding: "120px 0",
      },
      "& a": {
        display: "block",
        width: "calc(50% - 24px)",
        backgroundColor: "#4B3048",
        margin: 12,
        transition: "opacity .3s ease-in-out",
        "@media(min-width: 600px)": {
          width: "calc(33.333% - 24px)",
        },
        "@media(min-width: 960px)": {
          width: "calc(25% - 24px)",
        },
        "&:hover, &:focus": {
          opacity: 0.9,
        },
        "& img": {
          width: "100%",
          display: "block",
          aspectRatio: "1",
          objectFit: "cover",
        },
        "& span": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          fontSize: 18,
          padding: "20px 28px",
          "& svg": {
            marginLeft: 24,
          },
        },
      },
    },
  },
}

const queryLocationSearch = (param = "", next = "") => {
  return {
    query: `
    {
      locations(first: 50, where: {search: "${param}"}, after: "${next}") {
        edges {
          node {
            id
            locations {
              address1
              address2
              city
              companyName
              county
              email
              latitude
              longitude
              phone
              postcode
              stockist {
                ... on Stockist {
                  id
                  stockists {
                    offline
                    online
                    url
                    searchRanking
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  }
}

class FindRetailer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      results: undefined,
      sort: "distance",
      searchCoordinaties: {
        latitude: null,
        longitude: null,
      },
      searchName: "",
      onlineRetailerToggle: "premium",
      zoom: 12,
    }

    this.searchStockist = this.searchStockist.bind(this)
    this.googleMapRef = this.googleMapRef.bind(this)
    this.onZoomHandler = this.onZoomHandler.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.searchByLocation = this.searchByLocation.bind(this)
    this.searchByName = this.searchByName.bind(this)
    if (isBrowser) this.getUserLocation()
  }

  getUserLocation() {
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords
        this.searchByCoordinates(Number(latitude), Number(longitude))
      })
    }
  }

  resetForm() {
    this.setState({
      results: undefined,
      searchName: "",
    })
  }

  searchStockist(name, town, postcode, type) {
    this.setState({
      results: undefined,
      searchName: name,
    })

    if (town || postcode) {
      this.searchByLocation(name, town, postcode, type)
    } else if (name) {
      this.searchByName(null, [], name)
    }
  }

  searchByLocation(name, town, postcode, type) {
    axios
      .get(
        `${
          process.env.GATSBY_BACKEND_URL
        }/wp-json/brew/v1/search-locations/?location=${name ? name : ""}${
          name && town ? "," : ""
        }${town ? town : ""}${town && postcode ? "," : ""}${
          postcode ? postcode : ""
        }`
      )
      .then(res => {
        if (!res.data.data.locations) {
          return this.setState({
            results: [],
            searchCoordinaties: {
              latitude: null,
              longitude: null,
            },
          })
        }

        const results = res.data.data.locations
          .filter(stockist => {
            if (!name) return true
            return stockist.title.includes(name)
          })
          .map(stockist => {
            const dataFound =
              this.props.data.allWpStockist.edges.find(st => {
                const encodedTitle = encodeHTML(stockist.title)
                return st.node.title === encodedTitle
              }) || {}
            return { ...stockist, ...dataFound.node }
          })
          .filter(stockist => {
            if (!stockist || !stockist.stockists) return false

            return true
          })

        if (results.length > 0) {
          this.setState({
            results,
            searchCoordinaties: {
              latitude: Number(results[0].latitude),
              longitude: Number(results[0].longitude),
            },
          })
        } else {
          this.setState({
            results: [],
            searchCoordinaties: {
              latitude: null,
              longitude: null,
            },
          })
        }
      })
  }

  searchByCoordinates(latitude, longitude) {
    axios
      .get(
        `${process.env.GATSBY_BACKEND_URL}/wp-json/brew/v1/search-locations?longitude=${longitude}&latitude=${latitude}`
      )
      .then(res => {
        const results = res.data.data.locations
          .map(stockist => {
            const dataFound =
              this.props.data.allWpStockist.edges.find(st => {
                const encodedTitle = encodeHTML(stockist.title)
                return st.node.title === encodedTitle
              }) || {}
            return { ...stockist, ...dataFound.node }
          })
          .filter(stockist => {
            if (!stockist || !stockist.stockists) return false

            return true
          })

        if (results.length > 0) {
          this.setState({
            results,
            searchCoordinaties: {
              latitude: Number(results[0].latitude),
              longitude: Number(results[0].longitude),
            },
          })
        } else {
          this.setState({
            results: [],
            searchCoordinaties: {
              latitude: null,
              longitude: null,
            },
          })
        }
      })
  }

  searchByName(next = "", prevData = [], query) {
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryLocationSearch(query, next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        return {
          locations: res.data.data.locations.edges
            .map(node => node.node.locations)
            .map(location => {
              return {
                address_1: location.address1,
                address_2: location.address2,
                city: location.city,
                company_name: location.companyName,
                county: location.county,
                latitude: location.latitude,
                longitude: location.longitude,
                postcode: location.postcode,
                stockists: location.stockist.stockists,
                title: location.companyName,
                email: location.email,
                phone: location.phone,
              }
            }),
          next: res.data.data.locations.pageInfo.endCursor,
          hasNextPage: res.data.data.locations.pageInfo.hasNextPage,
        }
      })
      .then(data => {
        if (data.hasNextPage) {
          this.searchByName(data.next, prevData.concat(data.locations), query)
        } else {
          const results = prevData.concat(data.locations)

          if (results.length > 0) {
            this.setState({
              results,
              searchCoordinaties: {
                latitude: Number(results[0].latitude),
                longitude: Number(results[0].longitude),
              },
            })
          } else {
            this.setState({
              results: [],
            })
          }
        }
      })
  }

  googleMapRef(map, maps) {
    const googleRef = maps
    const bounds = new googleRef.LatLngBounds()

    this.state.results &&
      this.state.results.map(stockist => {
        const newPoint = new googleRef.LatLng(
          Number(stockist.latitude),
          Number(stockist.longitude)
        )
        bounds.extend(newPoint)
        return stockist
      })

    map.fitBounds(bounds)
  }

  onZoomHandler(zoom) {
    this.setState({ zoom })
  }

  render() {
    const { classes } = this.props

    const offlineRetailers =
      this.state.results &&
      this.state.results
        .filter(stockist => stockist.stockists.offline)
        .sort((a, b) => {
          if (this.state.sort === null) return b.searchRanking - a.searchRanking
          if (this.state.sort === "name") return a.title.localeCompare(b.title)
          if (this.state.sort === "distance") return a - b
          return null
        })

    const onlineRetailers = this.props.data.allWpStockist.edges.filter(
      stockist => stockist.node.stockists.online
    )

    const orderedRetailers = []

    onlineRetailers
      .filter(retailer => {
        return !orderedRetailers
          .filter(r => r)
          .map(r => r.node.title)
          .includes(retailer.node.title)
      })
      .map(retailer => {
        orderedRetailers.push(retailer)
        return null
      })

    orderedRetailers.sort((a, b) => {
      return b.node.stockists.searchRanking - a.node.stockists.searchRanking
    })

    return (
      <>
        <Intro />

        <div className="wrapper">
          <div
            className={`${classes.stockistBlock} ${
              this.state.results && this.state.results.length > 0
                ? "displaying-results"
                : ""
            } `}
          >
            <CoreHeadingBlock
              attributes={{
                textAlign: "left",
                anchor: "",
                className: "",
                content: "Store stockists",
                level: 2,
                textColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <Form
              searchStockist={this.searchStockist}
              resetForm={this.resetForm}
            />

            {this.state.results && this.state.results.length === 0 && (
              <div>
                <p style={{ fontSize: 16, marginTop: 30, lineHeight: 1.8 }}>
                  Unfortunately we can't find any stockists that match your
                  search terms. Please check the spelling and address of the
                  store you are searching for. Alternatively, please{" "}
                  <a
                    href="/contact-us"
                    style={{ color: "#009f4d", fontWeight: "bold" }}
                  >
                    contact us
                  </a>{" "}
                  for more information.
                </p>
              </div>
            )}

            {this.state.results && this.state.results.length > 0 && (
              <div className="map">
                <div style={{ height: "800px", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.GATSBY_GMAP_KEY,
                    }}
                    defaultCenter={{
                      lat: 52.647468540923164,
                      lng: -1.1149952019956058,
                    }}
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.googleMapRef(map, maps)
                    }
                    onZoomAnimationEnd={zoom => this.onZoomHandler(zoom)}
                    center={{
                      lat: this.state.searchCoordinaties.latitude,
                      lng: this.state.searchCoordinaties.longitude,
                    }}
                    defaultZoom={12}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    {this.state.results.map((stockist, index) => {
                      return (
                        <Marker
                          key={index}
                          lat={stockist.latitude}
                          lng={stockist.longitude}
                          text={index + 1}
                          style={{
                            transform: `scale(${this.state.zoom /
                              14}) translate(-50%, -50%)`,
                          }}
                        />
                      )
                    })}
                  </GoogleMapReact>
                </div>
              </div>
            )}

            {offlineRetailers && offlineRetailers.length > 0 && (
              <section>
                <div className={classes.subCatNav}>
                  <span className="count">
                    <strong>Results:</strong> {offlineRetailers.length}{" "}
                    {offlineRetailers.length === 1 ? "item" : "items"}
                  </span>
                  <div className="sort-wrap">
                    <div className="select-row">
                      <span>Sort by</span>
                      <select
                        value={this.state.sort}
                        onChange={e => this.setState({ sort: e.target.value })}
                      >
                        <option value="distance">Distance</option>
                        <option value="name">Name</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  {offlineRetailers.map((result, index) => {
                    return (
                      <ResultItem
                        key={result.company_name}
                        id={index + 1}
                        name={result.company_name}
                        address={`${
                          result.address_1 ? result.address_1 + "," : ""
                        }
                          ${result.address_2 ? result.address_2 + "," : ""}
                          ${result.city ? result.city + "," : ""}
                          ${result.county ? result.county + "," : ""}
                          ${result.postcode}`}
                        distance={
                          result.distance ? result.distance + " Miles" : null
                        }
                        phone={result.phone}
                        email={result.email}
                        url={result.stockists}
                      />
                    )
                  })}
                </div>
              </section>
            )}
          </div>
        </div>
        <br />
        <br />

        {orderedRetailers && orderedRetailers.length > 0 && (
          <section className={classes.onlineRetailers}>
            <div className="retailer-listing">
              <div className="wrapper">
                <CoreHeadingBlock
                  attributes={{
                    textAlign: "left",
                    anchor: "",
                    className: "",
                    content: "Online retailers",
                    level: 2,
                    textColor: "",
                    __typename: "WpCoreHeadingBlockAttributes",
                  }}
                  innerBlocks={[]}
                />
              </div>
              <div className="wrapper">
                {orderedRetailers.map(retailer => {
                  if (!retailer) return null
                  return (
                    // eslint-disable-next-line
                    <a href={retailer.node.stockists.url} target="_blank">
                      <img
                        src={
                          retailer.node.stockists.logo
                            ? retailer.node.stockists.logo.sourceUrl
                            : "https://via.placeholder.com/140x100"
                        }
                        alt=""
                        loading="lazy"
                      />
                      <span>
                        <strong>{retailer.node.title}</strong>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.003"
                          height="18.342"
                          viewBox="0 0 19.003 18.342"
                        >
                          <g
                            id="Group_21200"
                            data-name="Group 21200"
                            transform="translate(-1195 -1301.658)"
                          >
                            <g
                              id="Path_28906"
                              data-name="Path 28906"
                              transform="translate(1195 1306)"
                              fill="none"
                            >
                              <path
                                d="M4,0c8.209,0,10,1.791,10,10a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
                                stroke="none"
                              />
                              <path
                                d="M 4 2 C 2.897199630737305 2 2 2.897199630737305 2 4 L 2 10 C 2 11.1028003692627 2.897199630737305 12 4 12 L 10 12 C 11.1028003692627 12 12 11.1028003692627 12 10 C 12 6.265120029449463 11.60050964355469 4.272080421447754 10.66421031951904 3.335789680480957 C 9.727920532226562 2.399490356445312 7.734879970550537 2 4 2 M 4 0 C 12.20913982391357 0 14 1.790860176086426 14 10 C 14 12.20913982391357 12.20913982391357 14 10 14 L 4 14 C 1.790860176086426 14 0 12.20913982391357 0 10 L 0 4 C 0 1.790860176086426 1.790860176086426 0 4 0 Z"
                                stroke="none"
                                fill="#b3d57c"
                              />
                            </g>
                            <path
                              id="Path_28907"
                              data-name="Path 28907"
                              d="M3.994,0c8.2,0,9.985,1.788,9.985,9.985a3.994,3.994,0,0,1-3.994,3.994H3.994A3.994,3.994,0,0,1,0,9.985V3.994A3.994,3.994,0,0,1,3.994,0Z"
                              transform="translate(1200.021 1302)"
                              fill="#4b3048"
                            />
                            <path
                              id="Path_28908"
                              data-name="Path 28908"
                              d="M1497.349-439l-3.932,3.932L1489.485-439"
                              transform="translate(2572.867 2054.805) rotate(-135)"
                              fill="none"
                              stroke="#b3d57c"
                              strokeLinecap="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  )
                })}
              </div>
            </div>
          </section>
        )}
      </>
    )
  }
}

const Page = ({ data }) => {
  const StyledPage = withStyles(styles)(FindRetailer)

  return (
    <Layout
      meta={{
        metaTitle: "Find a Retailer | Zest Wooden Outdoor Products",
        metaDescription:
          "The Zest range of wooden garden structures and products are stocked by over 500 stores and garden centres across the UK, and many online retailers.",
      }}
      path={"/find-a-retailer/"}
      title="Account"
    >
      <Breadcrumb
        type="page"
        ancestors={null}
        current={{
          title: "Find a Retailer",
          slug: "find-a-retailer",
          uri: "/find-a-retailer",
        }}
      />
      <StyledPage data={data} />
      <CookiesConsent></CookiesConsent>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpStockist(limit: 500) {
      edges {
        node {
          id
          title
          stockists {
            featured
            logo {
              sourceUrl
            }
            offline
            online
            url
            searchRanking
          }
        }
      }
    }
  }
`
export default Page
