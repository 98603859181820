export default theme => ({ // eslint-disable-line
    form: {
        backgroundColor: '#E4DFD9',
        padding: 24,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-end',
        borderTopLeftRadius: theme.border.radius,
        borderTopRightRadius: theme.border.radius,
        '& form': {
            flex: '0 0 100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(100% - 90px)',
                maxWidth: 'calc(100% - 90px)',
            },
        },
        '& .form-item': {
            flex: '0 0 100%',
            [theme.breakpoints.up('md')]: {
                padding: 12,
                flex: '1',
            },
            '& label': {
                display: 'block',
                fontSize: 12,
                letterSpacing: '0.152em',
                fontWeight: 'bold',
                color: theme.palette.primary.purple,
                textTransform: 'uppercase',
                fontFamily: theme.bodyFont,
                lineHeight: 1,
                marginBottom: 10,
            },
            '& input, & button': {
                padding: '15px 12px',
                fontSize: 16,
                letterSpacing: '0.016em',
                color: '#333',
                borderRadius: theme.border.radius,
                border: '1px solid ' + theme.palette.secondary.beige,
                backgroundColor: theme.common.white,
                marginBottom: 12,
                display: 'block',
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                }
            },
            '& button': {
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
                '& svg': {
                    marginLeft: 4,
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                }
            },
            '&.stockist-search': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 160px',
                },
                '& button': {
                    padding: '18px 24px',
                    fontSize: 18,
                    lineHeight: 1,
                    color: theme.palette.primary.navy,
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.primary.green,
                    border: 'none',
                    boxShadow: theme.shadow,
                    '& svg': {
                        width: 18,
                        height: 18,
                    }
                }
            },
            '&.stockist-reset': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 85px',
                },
                '& button': {
                    padding: '18px 0',
                    fontSize: 14,
                    lineHeight: 1,
                    color: theme.palette.primary.purple,
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    border: 'none',
                    marginBottom: 0,
                    '& svg': {
                        width: 14,
                        height: 14,
                    }
                }
            }
        }
    },
  })
  

