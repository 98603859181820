const style = theme => ({
  Marker: {
    "& span": {
      color: theme.palette.secondary.cream,
      backgroundColor: theme.palette.primary.purple,
      width: 24,
      height: 24,
      lineHeight: "24px",
      fontSize: 12,
      fontWeight: "bold",
      display: "block",
      borderRadius: "50%",
      position: "absolute",
      right: -50,
      top: -20,
      textAlign: "center",
    },
    "& svg": {
      position: "absolute",
      bottom: 0,
      left: 0,
      transform: "translate(-22px, 0)",
      color: theme.palette.secondary.main,
    },
  },
})

export default style
