import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./index.styles"

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock"
import { CoreParagraphBlock } from "../../blocks/CoreParagraphBlock"

const useStyles = makeStyles(theme => styles(theme))

export default function Intro() {
  const classes = useStyles()

  return (
    <div className={`${classes.pageListing} `}>
      <div className="wrapper">
        <CoreHeadingBlock
          attributes={{
            textAlign: "left",
            anchor: "",
            className: "",
            content: "Find a Retailer",
            level: 1,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <CoreParagraphBlock
          attributes={{
            align: "left",
            className: "post-date",
            content:
              "<strong>Enquiring about product availability?</strong> If you're looking for a particular product please contact the retailerdirectly as we do not have access to store stock levels.",
            textColor: "initial",
            backgroundColor: "",
            fontSize: "small",
            preParagraph: "",

            __typename: "WpCoreParagraphBlockAttributes",
          }}
          innerBlocks={[]}
        />
      </div>
    </div>
  )
}
