import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./index.styles"
import scrollTo from "gatsby-plugin-smoothscroll"

const useStyles = makeStyles(theme => styles(theme))

export default function Marker({ text, style }) {
  const classes = useStyles()

  return (
    <button
      className={classes.Marker}
      style={style}
      onClick={() => {
        scrollTo(`.result-${text}`)
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="59.748"
        height="81.842"
        viewBox="0 0 59.748 81.842"
      >
        <g
          id="Group_21197"
          data-name="Group 21197"
          transform="translate(-619.946 -837.984)"
        >
          <g
            id="Path_28904"
            data-name="Path 28904"
            transform="translate(620 837.984)"
            fill="none"
          >
            <path
              d="M29.744,0C46.093,0,59.693,13.1,59.693,34.872S29.744,81.842,29.744,81.842-.054,58.43-.054,34.872,13.395,0,29.744,0Z"
              stroke="none"
            />
            <path
              d="M 29.74391174316406 7.999992370605469 C 15.8907470703125 7.999992370605469 7.945549011230469 17.79435729980469 7.945549011230469 34.87172317504883 C 7.945549011230469 47.9866828918457 20.82234954833984 62.8990478515625 29.59617805480957 71.12425231933594 C 38.33377838134766 62.55274200439453 51.69316482543945 46.89249801635742 51.69316482543945 34.87172317504883 C 51.69316482543945 18.79892349243164 42.8724365234375 7.999992370605469 29.74391174316406 7.999992370605469 M 29.74391174316406 -7.62939453125e-06 C 46.09300231933594 -7.62939453125e-06 59.69316482543945 13.09757995605469 59.69316482543945 34.87172317504883 C 59.69316482543945 56.64585113525391 29.74391174316406 81.84236145019531 29.74391174316406 81.84236145019531 C 29.74391174316406 81.84236145019531 -0.05445098876953125 58.43016052246094 -0.05445098876953125 34.87172317504883 C -0.05445098876953125 11.31328582763672 13.39480209350586 -7.62939453125e-06 29.74391174316406 -7.62939453125e-06 Z"
              stroke="none"
              fill="#4b3048"
            />
          </g>
          <g
            id="Ellipse_391"
            data-name="Ellipse 391"
            transform="translate(634.518 854.895)"
            fill="none"
            stroke="#4b3048"
            strokeWidth="8"
          >
            <circle cx="14.377" cy="14.377" r="14.377" stroke="none" />
            <circle cx="14.377" cy="14.377" r="10.377" fill="none" />
          </g>
        </g>
      </svg>
      <span>{text}</span>
    </button>
  )
}
