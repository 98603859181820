import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./index.styles"

import Link from "../../Link"

const useStyles = makeStyles(theme => styles(theme))

export default function ResultItem(props) {
  const classes = useStyles()

  return (
    <div className={`${classes.resultItem} result-${props.id}`}>
      <div className="number">{props.id}</div>
      <div className="stockist-info">
        <h3>{props.name}</h3>
        <p>{props.address}</p>
        <p>
          <a href={`tel:${props.phone}`}>{props.phone}</a>
        </p>
        <p>
          <a href={`mailto:${props.email}`}>{props.email}</a>
        </p>
      </div>
      {props.distance && (
        <div className="stockist-disatance">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.624"
            height="22.771"
            viewBox="0 0 16.624 22.771"
          >
            <g
              id="Group_21190"
              data-name="Group 21190"
              transform="translate(-648.946 -1341.113)"
            >
              <g
                id="Path_28905"
                data-name="Path 28905"
                transform="translate(649 1341.113)"
                fill="none"
              >
                <path
                  d="M8.236,0c4.549,0,8.333,3.644,8.333,9.7S8.236,22.771,8.236,22.771-.054,16.257-.054,9.7,3.688,0,8.236,0Z"
                  stroke="none"
                />
                <path
                  d="M 8.236324310302734 1.999998092651367 C 6.984953880310059 1.999998092651367 5.207314491271973 2.305377960205078 3.845014572143555 3.760198593139648 C 2.602374076843262 5.087238311767578 1.945553779602051 7.141998291015625 1.945553779602051 9.702328681945801 C 1.945553779602051 11.88785839080811 3.199443817138672 14.53176879882812 5.571654319763184 17.34820938110352 C 6.516839981079102 18.47038841247559 7.472654342651367 19.4175910949707 8.201597213745117 20.09175109863281 C 8.932518005371094 19.3856372833252 9.898553848266602 18.39400863647461 10.86374473571777 17.23111915588379 C 13.28780364990234 14.3105583190918 14.56909370422363 11.70714855194092 14.56909370422363 9.702328681945801 C 14.56909370422363 7.320858955383301 13.89777374267578 5.322637557983398 12.62772369384766 3.923688888549805 C 11.50151443481445 2.683177947998047 9.941953659057617 1.999998092651367 8.236324310302734 1.999998092651367 M 8.236324310302734 -1.9073486328125e-06 C 12.78512382507324 -1.9073486328125e-06 16.56909370422363 3.644128799438477 16.56909370422363 9.702328681945801 C 16.56909370422363 15.76052856445312 8.236324310302734 22.77093887329102 8.236324310302734 22.77093887329102 C 8.236324310302734 22.77093887329102 -0.05444526672363281 16.25697898864746 -0.05444526672363281 9.702328681945801 C -0.05444526672363281 3.147687911987305 3.687534332275391 -1.9073486328125e-06 8.236324310302734 -1.9073486328125e-06 Z"
                  stroke="none"
                  fill="#4b3048"
                />
              </g>
              <g
                id="Ellipse_392"
                data-name="Ellipse 392"
                transform="translate(653 1345.82)"
                fill="none"
                stroke="#4b3048"
                strokeWidth="2"
              >
                <circle cx="4" cy="4" r="4" stroke="none" />
                <circle cx="4" cy="4" r="3" fill="none" />
              </g>
            </g>
          </svg>
          <span>{props.distance}</span>
        </div>
      )}
      {props.url != null && (
        <div className="stockist-link">
          <Link to={props.url.url}>
            Visit Website
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.003"
              height="18.341"
              viewBox="0 0 19.003 18.341"
            >
              <g
                id="Group_21189"
                data-name="Group 21189"
                transform="translate(-1195 -1301.659)"
              >
                <g
                  id="Path_28906"
                  data-name="Path 28906"
                  transform="translate(1195 1306)"
                  fill="none"
                >
                  <path
                    d="M4,0c8.209,0,10,1.791,10,10a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
                    stroke="none"
                  />
                  <path
                    d="M 4 2 C 2.897199630737305 2 2 2.897199630737305 2 4 L 2 10 C 2 11.1028003692627 2.897199630737305 12 4 12 L 10 12 C 11.1028003692627 12 12 11.1028003692627 12 10 C 12 6.265120029449463 11.60050964355469 4.272080421447754 10.66421031951904 3.335789680480957 C 9.727920532226562 2.399490356445312 7.734879970550537 2 4 2 M 4 0 C 12.20913982391357 0 14 1.790860176086426 14 10 C 14 12.20913982391357 12.20913982391357 14 10 14 L 4 14 C 1.790860176086426 14 0 12.20913982391357 0 10 L 0 4 C 0 1.790860176086426 1.790860176086426 0 4 0 Z"
                    stroke="none"
                    fill="#00313c"
                  />
                </g>
                <path
                  id="Path_28907"
                  data-name="Path 28907"
                  d="M3.994,0c8.2,0,9.985,1.788,9.985,9.985a3.994,3.994,0,0,1-3.994,3.994H3.994A3.994,3.994,0,0,1,0,9.985V3.994A3.994,3.994,0,0,1,3.994,0Z"
                  transform="translate(1200.021 1302)"
                  fill="#b3d57c"
                />
                <path
                  id="Path_28908"
                  data-name="Path 28908"
                  d="M1497.349-439l-3.932,3.932L1489.485-439"
                  transform="translate(2572.867 2054.805) rotate(-135)"
                  fill="none"
                  stroke="#00313c"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </Link>
        </div>
      )}
    </div>
  )
}
