import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"

import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => styles(theme))

export default function Form({ searchStockist, resetForm }) {
  const classes = useStyles()

  const [name, setName] = useState()
  const [town, setTown] = useState()
  const [postcode, setPostcode] = useState()
  const [type, setType] = useState("Store")

  const onSubmitHandler = e => {
    e.preventDefault()
    searchStockist(name, town, postcode, type)
  }

  const reset = () => {
    setName("")
    setTown("")
    setPostcode("")
    setType("Store")
    resetForm()
  }

  return (
    <div className={classes.form}>
      <form onSubmit={onSubmitHandler}>
        <div className="form-item stockist-name">
          <label htmlFor="stockist-name">Stockist Name</label>
          <input
            type="text"
            name="stockist-name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div className="form-item stockist-town">
          <label htmlFor="town">Town</label>
          <input
            type="text"
            name="town"
            value={town}
            onChange={e => setTown(e.target.value)}
          />
        </div>

        <div className="form-item stockist-postcode">
          <label htmlFor="postcode">Postcode</label>
          <input
            type="text"
            name="postcode"
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
          />
        </div>

        <div className="form-item stockist-search">
          <button type="submit">
            Search <SearchIcon />
          </button>
        </div>
      </form>

      <div className="form-item stockist-reset">
        <button onClick={reset}>
          Reset <CloseIcon />
        </button>
      </div>
    </div>
  )
}
